
import { mapState } from "vuex";
import { gql } from "graphql-tag";

import { documentLink } from "~/app/prismic/prismic-fragments";

const HEADER_QUERY = gql`
  query header($uid: String!, $lang: String!) {
    header(uid: $uid, lang: $lang) {
      id: _meta {
        id
      }
      site_title
      site_description
      site_logo
      mobile_banner
      mobile_banner_link {
        ...documentLink
      }
      information_banner {
        banner_text
      }
      information_banner_time_interval
      information_banner_color
      information_banner_text_color
      information_banner_font_size
      mega_menu_links {
        link_label
        link_color
        link {
          ...documentLink
        }
        second_level_menu {
          ... on Second_level_menu {
            body {
              ... on Second_level_menuBody2nd_level {
                type
              }
            }
          }
        }
      }
      header_icons {
        icon_name
        icon_link {
          ...documentLink
        }
      }
      meta_tags {
        name
        content
      }
    }
  }
  ${documentLink}
`;

export default {
  data: () => ({ header: null, resizeObserver: null, animationFrame: null }),

  async fetch() {
    const { header } = await this.$getPrismicData({
      query: HEADER_QUERY,
      variables: { uid: "site-header" },
    });
    this.header = header;
  },

  head() {
    return {
      script: [
        ...this.tealiumScripts,
        this.preezieScript,
        { src: this.$config.prismicPreviewURL, body: true, defer: true },
      ],
      meta: [
        {
          name: this.header?.meta_tags?.[0]?.name?.[0]?.text ?? "",
          content: this.header?.meta_tags?.[0]?.content?.[0]?.text ?? "",
        },
      ],
    };
  },

  computed: {
    ...mapState(["siteConfig"]),
    ...mapState("app", [
      "activeMenuIndex",
      "isMegaMenuOpen",
      "isFilterPanelOpen",
      "topBannerHeight",
      "quickviewProduct",
    ]),
    preezieScript() {
      // recommended to not use defer or async with Preezie script in documentation.
      return {
        hid: "preezie-script",
        src: this.$config.preezieScriptUrl,
        body: true,
        callback: () => {
          if (typeof window.PREEZIE_GUIDE !== "undefined") {
            window.PREEZIE_GUIDE.render([
              {
                guideKey: this.$config.preezieGuideKey,
                version: "1.0.0",
                renderTo: "preezie-widget-div-id-popup",
              },
            ]);
          }
        },
      };
    },
    tealiumScripts() {
      return [
        {
          hid: "tealium-spa",
          pbody: true,
          type: "text/javascript",
          innerHTML: `
            window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
            window.utag_cfg_ovrd.noview = true`,
        },
        {
          hid: "tealium-script",
          src: this.$config.tealiumScriptUrl,
          pbody: true,
          async: true,
          callback: async () => {
            await this.$store.dispatch("app/setTealiumLoaded");
          },
        },
      ];
    },
    informationBannerProps() {
      return {
        banners: this.header?.information_banner,
        fontSize: this.header?.information_banner_font_size,
        textColor: this.header?.information_banner_text_color,
        bannerColor: this.header?.information_banner_color,
        timeInterval: this.header?.information_banner_time_interval,
      };
    },
  },

  mounted() {
    if (window.ResizeObserver == undefined) {
      return;
    }

    this.resizeObserver = new ResizeObserver(entries => {
      // Reference from https://github.com/nolimits4web/swiper/pull/5441/files
      this.animationFrame = window.requestAnimationFrame(() => {
        let entry = entries[0];
        let width;
        if (entry.contentBoxSize) {
          // Checking for chrome as using a non-standard array
          if (entry.contentBoxSize[0]) {
            width = entry.contentBoxSize[0].inlineSize;
          } else {
            width = entry.contentBoxSize.inlineSize;
          }
          this.$store.commit("app/SET_WINDOW_WIDTH", width);
        } else {
          this.$store.commit("app/SET_WINDOW_WIDTH", entry.contentRect.width);
        }
      });
    });
    this.resizeObserver.observe(this.$nuxt.$el, { box: "border-box" });

    this.$store.dispatch(
      "app/currentOrientation",
      window.matchMedia("(orientation: portrait)")
    );
  },

  destroyed() {
    if (this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame);
    }

    this.resizeObserver?.unobserve(this.$nuxt.$el);
  },
};
