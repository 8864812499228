
import { mapState } from "vuex";

export default {
  props: {
    headerIcons: { type: Array, default: () => [] },
  },

  data() {
    return {
      timerObj: null,
      title: {
        search: "Search",
        location: "Location",
        profile: "Become a Member",
        cart: "Cart",
      },
    };
  },

  computed: {
    ...mapState("app", ["isMegaMenuOpen", "activeMenuIndex"]),
    ...mapState("bag", ["miniCart"]),
    showNotificationCount() {
      return Boolean(this.miniCart.lineItemsCount);
    },
    isCartPage() {
      return this.$route.path.includes("/cart");
    },
    formattedCount() {
      return this.miniCart.lineItemsCount >= 100
        ? "99+"
        : this.miniCart.lineItemsCount;
    },
    isChildMenuOpened() {
      return (
        this.activeMenuIndex > -1 &&
        (this.$device.isMobileOrTablet || this.isTablet)
      );
    },
    isMegaMenuOpened() {
      return this.isMegaMenuOpen || this.isChildMenuOpened;
    },
    icons() {
      return this.headerIcons.filter(
        icon =>
          icon.icon_name === "search" || (icon.icon_name && icon.icon_link)
      );
    },
  },

  methods: {
    checkDesktopAndNonCartPage(icon) {
      return this.$device.isDesktop && icon === "cart" && !this.isCartPage;
    },
    hideMegaMenu() {
      this.$store.dispatch("app/closeMegaMenu");
    },
    onMouseEnter(icon) {
      if (this.checkDesktopAndNonCartPage(icon)) {
        this.$emit("onCartIconMouseEnter");
      }
    },
    onMouseOut(icon) {
      if (this.checkDesktopAndNonCartPage(icon)) {
        this.$emit("onCartIconMouseLeave");
      }
    },
  },
};
