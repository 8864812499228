export const state = () => ({
  productsFetchingStatus: {},
  recommendedProduct: null,
  searchspringProducts: null,
});

export const getters = {
  getProductFetchingStatus(state) {
    return productHandle => state.productsFetchingStatus[productHandle] ?? true;
  },
  getSearchspringProducts(state) {
    return (tag, productId) => {
      const productKey = productId ? `-${productId}` : "";
      const key = `${tag}${productKey}`;

      if (
        !(state.searchspringProducts && key && state.searchspringProducts[key])
      ) {
        return [];
      }

      return state.searchspringProducts[key];
    };
  },
};

export const actions = {
  setRecommendedProduct({ commit }, product) {
    commit("setRecommendedProduct", product);
  },
  setProductsFetching({ commit }, { productHandles, isFetching }) {
    productHandles.forEach(productHandle => {
      commit("setProductsFetchingStatus", {
        productHandle,
        isFetching,
      });
    });
  },
  clearSearchspringProducts({ commit }) {
    commit("clearSearchspringProducts");
  },
  async fetchSearchspringProducts(
    { commit, getters },
    { tag, url, productId } = {}
  ) {
    const products = getters.getSearchspringProducts(tag, productId);
    if (products.length != 0) {
      return;
    }

    const { data } = await this.$axios.get(url);
    const results = data.length > 0 ? data[0].results : [];
    commit("setSearchspringProducts", {
      tag,
      productId,
      products: results,
    });
  },
};

export const mutations = {
  setProductsFetchingStatus(state, { productHandle, isFetching }) {
    state.productsFetchingStatus = {
      ...state.productsFetchingStatus,
      [productHandle]: isFetching,
    };
  },
  setRecommendedProduct(state, product) {
    state.recommendedProduct = product;
  },
  setSearchspringProducts(state, { tag, productId, products }) {
    const productKey = productId ? `-${productId}` : "";
    const key = `${tag}${productKey}`;
    state.searchspringProducts = {
      ...state.searchspringProducts,
      [key]: products,
    };
  },
  clearSearchspringProducts(state) {
    state.searchspringProducts = null;
  },
};
