import slugify from "./slugify";
import camelize from "./camelize";
import getAttribute from "./get-attribute";
import getShopifyGlobalId from "./get-shopify-global-id";
import normalizeCart from "./normalize-cart";
import groupByProp from "./group-by-prop";
import templateString from "./template-string-utils";
import hasSingleSize from "./has-single-size";
import normalizeEdges from "./normalize-edges";
import { getProduct, getVariant } from "./normalize-product";
import { normalizeId, normalizeMetafields } from "./normalize-shopify";

const formatMoney = (value, currency) => {
  return `$${parseFloat(value).toFixed(2)} ${currency}`;
};

export {
  slugify,
  camelize,
  getAttribute,
  getShopifyGlobalId,
  normalizeCart,
  groupByProp,
  templateString,
  hasSingleSize,
  getVariant,
  normalizeEdges,
  getProduct,
  normalizeId,
  normalizeMetafields,
  formatMoney,
};
