export default ({ redirect }) => {
  window.addEventListener(
    "click",
    event => {
      if (event.target.matches("a[data-utag-link]")) {
        event.preventDefault();
        const { externalLink, ...utagData } = event.target.dataset;
        window?.utag?.link(utagData);

        window.open(
          `${event.target.origin}${event.target.pathname}`,
          event.target.getAttribute("data-target"),
          event.target.getAttribute("data-rel")
        );
        return;
      }

      if (event.target.matches("a[data-nuxt-link]")) {
        // Don't follow the link
        event.preventDefault();

        // Push link destination to router
        redirect(event.target.pathname);
      }
    },
    false
  );
};
