import { render, staticRenderFns } from "./default.vue?vue&type=template&id=1161f8e5&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=1161f8e5&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1161f8e5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppTopBanner: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppTopBanner.vue').default,SiteHeader: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/layout/SiteHeader.vue').default,MainCampaignBanner: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/layout/MainCampaignBanner.vue').default})
