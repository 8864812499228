import { mapState } from "vuex";

const EVENT_CATEGORY_ECOMMERCE = "enhanced ecommerce";
const NON_INTERACTION_HIT = "0";
const EVENT_CATEGORY_NAVIGATION = "Navigation";
const EVENT_CATEGORY_BAG = "cart";
const EVENT_CATEGORY_PRODUCT = "product detail";
const EVENT_ACTION_CLICK = "click";
const COUNTRY_CODE = "au";
const LANGUAGE_CODE = "en";

export default {
  data: () => ({
    utagData: null,
    utagDataAdded: false,
    utagScriptAdded: false,
  }),

  computed: {
    ...mapState("app", [
      "isTealiumLoaded",
      "tealiumPageType",
      "tealiumPageName",
      "tealiumPageTitle",
      "redirectFrom",
    ]),
    utagDataAndScriptLoaded() {
      return this.utagDataAdded && this.isTealiumLoaded;
    },
  },

  created() {
    const unwatchData = this.$watch("utagData", value => {
      if (value != null) {
        this.utagDataAdded = true;
        unwatchData();
      }
    });

    const unwatch = this.$watch("utagDataAndScriptLoaded", value => {
      if (value) {
        this._utagView();
        unwatch();
      }
    });
  },

  methods: {
    _utagView() {
      window?.utag?.view({
        country_code: COUNTRY_CODE,
        language_code: LANGUAGE_CODE,
        page_type: this.tealiumPageType,
        page_name: this.tealiumPageName,
        page_title: this.tealiumPageTitle,
        tealium_event: this.tealiumEvent,
        page_referrer: this.redirectFrom?.url
          ? this.redirectFrom?.url === "from search"
            ? `${window?.location?.origin}/products/search`
            : `${window?.location?.origin}${this.redirectFrom?.url}`
          : performance?.getEntriesByType("navigation")[0]?.type === "navigate"
          ? document.referrer
          : window?.location?.href,
        ...(this.utagData || {}),
      });
    },
    utagClick(data) {
      window?.utag?.link({
        event_action: EVENT_ACTION_CLICK,
        page_type: this.tealiumPageType,
        page_name: this.tealiumPageName,
        ...data,
      });
    },
    utagLinkClick(data) {
      this.utagClick({
        event_category: EVENT_CATEGORY_NAVIGATION,
        ...data,
      });
    },
    utagBagClick(data) {
      this.utagClick({
        event_category: EVENT_CATEGORY_BAG,
        ...data,
      });
    },
    utagProductClick(data) {
      this.utagClick({
        event_category: EVENT_CATEGORY_PRODUCT,
        ...data,
      });
    },
    utagEEcommerceClick(data) {
      this.utagClick({
        event_category: EVENT_CATEGORY_ECOMMERCE,
        non_interaction_hit: NON_INTERACTION_HIT,
        ...data,
      });
    },
    getUtagDataStr(data) {
      const utagData = {
        event_category: EVENT_CATEGORY_NAVIGATION,
        event_action: EVENT_ACTION_CLICK,
        page_type: this.tealiumPageType,
        page_name: this.tealiumPageName,
        ...data,
      };

      return Object.entries(utagData).reduce(
        (acc, [key, value]) => `${acc} data-${key}="${value}"`,
        ""
      );
    },
  },
};
