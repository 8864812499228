import { mapState } from "vuex";

const tealiumPages = { StoreLocator: "store", Contact_us: "contact" };

export default {
  provide() {
    return {
      uid: this.uid,
      title: this.title,
      pageType: this.pageType,
      metaTitle: this.metaTitle,
      tealiumEvent: this.tealiumEvent,
      collectionType: `all${this.pageType}s`,
      blogTags: this.blogTags ?? [],
    };
  },

  computed: mapState("app", ["redirectFrom"]),

  created() {
    this.$store.dispatch("app/setTealiumPageData", {
      tealiumPageType: tealiumPages?.[this.pageType] ?? this.pageType,
      tealiumPageName: this.uid,
      tealiumPageTitle: this.metaTitle,
    });
  },

  beforeMount() {
    const origin = window?.location.origin;
    const referrer = document?.referrer;
    const isNavigate =
      performance?.getEntriesByType("navigation")[0]?.type === "navigate";
    const pageReferrer = this.redirectFrom?.url
      ? this.redirectFrom?.url === "from search"
        ? `${origin}/products/search`
        : `${origin}${this.redirectFrom?.url}`
      : isNavigate
      ? referrer
      : window?.location.href;

    this.$gtm.gtagEvent("virtPath", {
      virtPath: this.$router?.history?.current?.path,
      virtTitle: this.metaTitle ?? "",
      page_location: `${origin}${this.$router?.history?.current?.fullPath}`,
      page_referrer: pageReferrer,
    });
  },

  mounted() {
    this.$gtm.push({ ecommerce: null });
  },

  beforeRouteLeave(to, from, next) {
    const title = this.title?.[0] ?? "";
    if (title) {
      const payload = { title, url: from.fullPath };
      this.$store.dispatch("app/setRedirectFrom", payload);
    }
    this.$store.dispatch("app/closeProductQuickview");
    this.$store.dispatch("app/closeAddedToBag", this.isTablet);
    this.$store.dispatch("products/clearSearchspringProducts");
    next();
  },
};
