import Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

const options = {
  plugins: [new BugsnagPluginVue()],
  appType: "storefront-app",
};

let bugsnagClient;

export default (_, inject) => {
  const allowedEnvs = JSON.parse(`["production","staging","development","sit-development"]`);
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start({
      apiKey: "4f2096c1cecbd0e8c29361763207fe3b",
      appVersion: "1.38",
      releaseStage: "production",

      onError: function (event) {
        if (!allowedEnvs.includes(event.app.releaseStage)) {
          console.error(event.errors);
          return false;
        }
      },
      ...options,
    });
    Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);
  } else {
    bugsnagClient = Bugsnag._client;
  }

  const myBugSnagClient = {
    ...bugsnagClient,
    notifyApiError({ err, url, body, header } = {}) {
      bugsnagClient.notify(err, e =>
        e.addMetadata("my request", { url, body, header })
      );
    },
  };

  // Inject to context as $axios
  inject("bugsnag", myBugSnagClient);
};
