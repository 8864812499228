
import { utagViewMixin } from "~/mixins";

export default {
  mixins: [utagViewMixin],

  props: {
    field: { required: true, validator: v => v == null || Array.isArray(v) },
    wrapper: { type: String, required: false, default: "div" },
    maxChars: { type: Number, default: 0 },
  },

  computed: {
    updatedField() {
      if (this.maxChars === 0) {
        return this.field;
      }

      const reducedField = this.field.reduce(
        ({ field, remChars }, v) => ({
          field: [...field, { ...v, text: v.text.slice(0, remChars) }],
          remChars: remChars - v.text.length,
        }),
        // to accommodate three dots at end
        { field: [], remChars: this.maxChars - 3 }
      ).field;

      return [
        ...reducedField.slice(0, -1),
        ...reducedField
          .slice(-1)
          .map(f => ({ ...f, text: f.text.concat("...") })),
      ];
    },
  },

  methods: {
    htmlSerializer(type, element, content, children) {
      // Generate links to Prismic Documents as <router-link> components
      // Present by default, it is recommended to keep this
      const Elements = this.$prismic.dom.RichText.Elements;

      // Generate links to Prismic Documents as <router-link> components
      if (type === Elements.hyperlink) {
        const url = this.$prismic.asLink(element.data);
        if (element.data.link_type === "Document") {
          return `<a href="${url}" data-nuxt-link>${content}</a>`;
        }
      }

      if (type === Elements.preformatted) {
        return `${element.text}`;
      }

      // If the image is also a link to a Prismic Document, it will return a <router-link> component
      if (type === "image") {
        const {
          url,
          alt = "",
          copyright = "",
          dimensions: { width, height },
        } = element;

        const imgUrl = this.$img(url, { width, height });
        const _srcset = this.getSrcset(url);

        return `<img src="${imgUrl}" srcset="${_srcset.srcset}" sizes="${_srcset.size}" alt="${alt}" copyright="${copyright}" width="${width}" height="${height}" loading="lazy"/>`;
      }

      if (type === "hyperlink") {
        const url = this.$prismic.asLink(element.data);

        if (
          element.data.link_type === "Web" &&
          element.data.target === "_blank"
        ) {
          const utagStr = this.getUtagDataStr({
            event_label: content,
            target: "_blank",
            rel: "noreferrer",
          });

          return `<a href="${url}" ${utagStr} data-utag-link>${content}</a>`;
        }
      }

      // Return null to stick with the default behavior for everything else
      return null;
    },
    getSrcset(src) {
      return this.$img.getSizes(src, { sizes: "md:100vw lg:100vw xl:1400px" });
    },
  },
};
