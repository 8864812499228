import { CartItems } from "~/store/bag/queries.gql";

export default async function ({ app, query, store }) {
  const cartId = app.$cart.getCartId();

  if (!cartId) {
    return;
  }

  let cartData;
  if (process.server) {
    const { data } = await app.$getShopifyData({
      query: CartItems,
      variables: { cartId },
      fetchPolicy: "no-cache",
    });

    cartData = data?.cart;

    if (cartData != null) {
      app.store.dispatch("bag/SET_MINI_CART", cartData);
    }
  }

  // If cart is completed, clear cart.
  if ((cartData ?? store.state.bag.cart) == null) {
    await app.store.dispatch("bag/CLEAR_CART");
    return;
  }

  // update line items
  if ("update_line_items" in query) {
    await app.store.dispatch("bag/UPDATE_CART");
  }
}
