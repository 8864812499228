
import { gql } from "graphql-tag";
import { path } from "ramda";

import { pageMixin } from "~/mixins";
import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

const links = [
  {
    link_label: "Mattresses",
    link: {
      __typename: "Collection_page",
      _linkType: "Link.document",
      _meta: {
        id: "X6PI4RIAACEAN0J0",
        uid: "mattresses",
        type: "collection_page",
      },
    },
  },
  {
    link_label: "Bedroom Furniture",
    link: {
      __typename: "Collection_page",
      _linkType: "Link.document",
      _meta: {
        id: "X3HQ3BEAACIAycCB",
        uid: "bedroom-furniture",
        type: "collection_page",
      },
    },
  },
  {
    link_label: "Clearence Sale",
    link: {
      __typename: "Collection_page",
      _linkType: "Link.document",
      _meta: {
        id: "X3IRjhEAAMSfyvF8",
        uid: "autumn-sale-mattresses",
        type: "collection_page",
      },
    },
  },
  {
    link_label: "Find Store",
    link: {
      _linkType: "Link.document",
      _meta: {
        uid: "store-locator",
        type: "store-locator",
      },
    },
  },
  {
    link_label: "Snooze Member Program",
    link: {
      __typename: "Become_a_member",
      _linkType: "Link.document",
      _meta: {
        id: "X5_9ERAAACMAXgpZ",
        uid: "become-a-member",
        type: "become_a_member",
      },
    },
  },
  {
    link_label: "Bed",
    link: {
      __typename: "Collection_page",
      _linkType: "Link.document",
      _meta: {
        id: "X22g2xEAACEAt2BS",
        uid: "beds",
        type: "collection_page",
      },
    },
  },
];

const PAG_TYPE = "error_page";
const UID = "error-page";
const ERROR_PAGE_QUERY = gql`
  query error_page($uid: String!, $lang: String!) {
    error_page(uid: $uid, lang: $lang) {
      title
      helpful_links {
        link_label
        link {
          ...documentLink
          ...externalLink
        }
      }
    }
  }
  ${documentLink}
  ${externalLink}
`;

export default {
  name: "NuxtError",

  mixins: [pageMixin],

  props: { error: { type: Object, default: null } },

  data: () => ({ links: [], errorMessage: null }),

  async fetch() {
    try {
      const { $getPrismicData } = this.$nuxt.context.app;

      const { [PAG_TYPE]: data } = await $getPrismicData({
        query: ERROR_PAGE_QUERY,
        variables: { uid: UID },
      });

      const results = path(["helpful_links"], data);
      this.links = [...results];
    } catch (err) {
      this.$bugsnag.notify(err);
      this.links = links;
      this.errorMessage = err.message;
    }
  },

  head() {
    return {
      title: this.metaTitle,
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no",
        },
      ],
    };
  },

  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500;
    },
    metaTitle() {
      return (
        this.errorMessage ??
        this.error.message ??
        `<%= messages.client_error %>`
      );
    },
  },
};
